import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import PrevPageSvg from "./svg/prev-page";
import NextPageSvg from "./svg/next-page";

import {
  onLogin,
  onLogout,
  onSignup,
  loadUsers,
  removeUser,
} from "../store/user.actions.js";
import { LoginSignup } from "../pages/login-signup";
import { useDispatch, useSelector } from "react-redux";

export function AppHeader({ scrollTop }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userModule.user);


  const onBack = () => {
    navigate(-1);
  };

  const onForward = () => {
    navigate(+1);
  };

  const onUserController = () => {
    navigate("/login");
  };

  const logout = () => {
    dispatch(onLogout());
  };


  return (
    <div
      className='app-header'
      style={{ backgroundColor: `rgb(40,40,40,${scrollTop / 100})` }}
    >
      <div className='app-header-top-bar'>
        <div className='dir-btns'>
          <button onClick={onBack} className='go-back-btn'>
            <PrevPageSvg />
          </button>
          <button onClick={onForward} className='forward-btn'>
            <NextPageSvg />
          </button>
        </div>
        <div className='user-bar'>
          {user?.username && <button className="logout-btn" onClick={logout}>Logout</button>}
          <button className='user-icon' onClick={onUserController}>
            <svg
              role='img'
              fill='white'
              height='16'
              width='16'
              viewBox='0 0 16 16'
            >
              <path d='M6.233.371a4.388 4.388 0 015.002 1.052c.421.459.713.992.904 1.554.143.421.263 1.173.22 1.894-.078 1.322-.638 2.408-1.399 3.316l-.127.152a.75.75 0 00.201 1.13l2.209 1.275a4.75 4.75 0 012.375 4.114V16H.382v-1.143a4.75 4.75 0 012.375-4.113l2.209-1.275a.75.75 0 00.201-1.13l-.126-.152c-.761-.908-1.322-1.994-1.4-3.316-.043-.721.077-1.473.22-1.894a4.346 4.346 0 01.904-1.554c.411-.448.91-.807 1.468-1.052zM8 1.5a2.888 2.888 0 00-2.13.937 2.85 2.85 0 00-.588 1.022c-.077.226-.175.783-.143 1.323.054.921.44 1.712 1.051 2.442l.002.001.127.153a2.25 2.25 0 01-.603 3.39l-2.209 1.275A3.25 3.25 0 001.902 14.5h12.196a3.25 3.25 0 00-1.605-2.457l-2.209-1.275a2.25 2.25 0 01-.603-3.39l.127-.153.002-.001c.612-.73.997-1.52 1.052-2.442.032-.54-.067-1.097-.144-1.323a2.85 2.85 0 00-.588-1.022A2.888 2.888 0 008 1.5z'></path>
            </svg>
          </button>
          {user?.username && <div className="username">{user.username}</div>}
        </div>
      </div>
    </div>
  );
}

// -webkit-box-align: center;
// -ms-flex-align: center;
// -webkit-app-region: drag;
// align-items: center;
// display: -webkit-box;
// display: -ms-flexbox;
// display: flex;
// gap: 16px;
// pointer-events: none;

// border: 0;
// border-radius: 500px;
// color: #000;
// height: 40px;
// padding: 6px 48px;
// text-overflow: ellipsis;
// width: 100%;
